export default {
  btnLabelLogout: 'Logout',
  btnLabelLogin: 'Login',
  btnLabelBack: 'Back',
  hlSearchResult: 'Search',
  searchResultError: 'Could not load search results',
  SearchResults: 'search results',
  labelSearchResults: 'Brand Guide results',
  labelMediaLibraryResults: 'Media Library results',
  labelSearchResultsFound: '{numFound} pages found',
  paginationLinkLabelPrevious: 'Previous page',
  paginationLinkLabelNext: 'Next page',
  paginationLinkTitlePrevious: 'Go to previous page',
  paginationLinkTitleNext: 'Go to next page',
  linkTitleDownload: 'Download',
  linkTitleAddMyfiles: 'Add to MyFiles',
  linkTitleRaiseRequest: 'Raise Request',
  hlIconLibrary: 'Icon Library',
  msgNoPreviewAvailable: 'No preview available',

  hlPageForbidden: 'Forbidden',
  hlPageNotFound: 'Not Found',
  hlPageTimeout: 'Timeout',
  hlPageWeb2Print: 'Web2Print',
  hlPageWeb2PrintSub: 'Choose, Edit and Manage Your Web2Print Projects',
  hlPageTruckLabeling: 'Order Requests',
  labelTruckBrandingSortOrder: 'Order',
  labelTruckBrandingSortBy: 'Sort By',
  msgPageForbidden: 'Access to this page is restricted.',
  msgPageNotFound: 'The page you are looking for does not exist. Please check the URL, maybe there is a typo.',
  msgPageTimeout:
    'The network connection to the server timed out. Please check your internet connectivity and try again in a few minutes.',
  msgPageNotFoundRedirect: 'You will be redirected in {timeLeft} seconds.',

  btnEditUserProfile: 'User Profile',
  btnEditNavigation: 'Edit Navigation',
  btnEditUsergroups: 'Edit Usergroups',
  btnChangelog: 'Newsfeed',

  hlEditNavigation: 'Edit Navigation',
  hlEditNavigationLock: 'Navigation Lock',
  msgEditNavigationLockedByUser:
    'The navigation is locked by you. This implies that no other user can make any changes to the navigation as long as you are on this page.',
  msgEditNavigationLockedByDifferentUser:
    'The navigation has been locked by an other user. You can not make any changes. This page refreshes as soon as the other user unlocks the navigation.',
  msgEditNavigationWaitingForLock: '...waiting for navigation lock',
  btnResetNavigation: 'Discard changes',
  btnSaveNavigation: 'Save',
  msgNavigationSaveSuccessful: 'Saved changes',
  msgNavigationSaveFailed: 'Error saving changes',
  labelMenuElementIsChanged: 'New / changed',
  labelMenuElementHasDraft: 'Draft',
  labelMenuElementIsUnpublished: 'Unpublished',

  btnEditPage: 'Edit',
  btnPublishPageChanges: 'Publish',
  btnDiscardPageChanges: 'Discard changes',
  msgDiscardPageChanges: 'Do you really want to discard all unpublished changes?',
  btnDiscardPageChangesConfirm: 'Yes, discard changes',
  btnDiscardPageChangesDeny: 'No, keep changes',
  btnManageModules: 'Manage Modules',
  msgManageModulesHint: 'Manage Modules: Add content modules, change order of content modules',
  msgCancelModulesHint: 'Cancel: Discard changes',
  msgSaveModulesHint: 'Save changes',

  hlPublishDialog: 'Publish changes',
  msgPublishDialog:
    'Please choose one of the following options: "New content" and "Updated content" create an entry in the newsfeed, in addition to the individual description.',
  btnPublishDialogCancel: 'Cancel',
  btnPublishDialogConfirm: 'Save and publish',
  placeholderPublishDialogCustomMessage: 'Change description (required):',

  hlPublishOptionNew: 'New content',
  msgPublishOptionNew: 'Select to notify users about new content.',
  hlPublishOptionUpdate: 'Updated content',
  msgPublishOptionUpdate: 'Select to notify user about updated content.',
  hlPublishOptionNone: 'Marginal change',
  msgPublishOptionNone: 'Select to NOT notify users of changes.',

  btnEditModule: 'Edit',
  msgEditModuleHint: 'Edit module',
  btnCancelModule: 'Cancel',
  msgCancelModuleHint: 'Cancel: Discard changes',
  btnDeleteModule: 'Delete',
  msgDeleteModuleHint: 'Delete: Remove module',
  btnSaveModule: 'Save',
  msgSaveModuleHint: 'Save: Save changes',
  btnMoveUpModule: 'Up',
  msgMoveUpModuleHint: 'Up: Move module one position up/left',
  btnMoveDownModule: 'Down',
  msgMoveDownModuleHint: 'Down: Move module one position down/right',

  labelImageModule: 'Image',
  btnDownloadImage: 'Download',
  msgDownloadImageHint: 'Download: Save image on your device',
  btnClearImage: 'Clear',
  msgClearImageHint: 'Clear: Remove image',
  hlFavourites: 'Favorites',
  hlFavouritesContent: 'Your Brand Guide Bookmarks',
  msgFavouritesContent:
    'Here you can see your favorite pages in the Brand Guide.{br}Scroll down for your Media Library watchlist.',
  hlFavouritesAsset: 'Your Media Library Asset Watchlist',
  msgFavouritesAsset:
    'Here you can view the assets you added to your favorites in the Media Library.{br}Click on the heart icon to delete assets from your watchlist.',
  msgFavouritesAssetEmpty:
    '<h3>No assets selected yet?</h3>{br}Find the assets you need in our central hub – home to our communication materials created worldwide.{br} To add assets from the <a>Media Library</a> to your favorites, simply click on the heart icon to the left of an asset ID.',
  hlFavouritesTitle: 'Title',
  hlFavouritesLastUpdated: 'Last Updated',
  msgFavouritesEmpty:
    'You have not yet bookmarked any favorite pages. Please click <a>here</a> to browse our Brand Guide.',

  hlManagePermissions: 'Manage Permissions',
  btnResetPermissions: 'Discard changes',
  btnSavePermissions: 'Save',
  msgPermissionsSaveSuccessful: 'Saved changes',
  msgPermissionsSaveFailed: 'Error saving changes',
  tableHeaderPermissionsRole: 'Group / User',
  tableHeaderPermissionsView: 'View',
  tableHeaderPermissionsUpdate: 'Edit',
  tableHeaderPermissionsCreate: 'Add',
  tableHeaderPermissionsDelete: 'Delete',
  tableHeaderPermissionsViewDraft: 'Draft',
  tableHeaderPermissionsPublish: 'Publish',
  tableHeaderPermissionsSetGrants: 'Grants',
  labelAddUser: 'Add User',
  placeholderSearchUser: 'Search for username / e-mail',
  labelSearchUserUsername: 'Username',
  labelSearchUserMail: 'E-Mail',

  optionScopeInherit: 'Inherited permissions',
  optionScopeSubtree: 'Subtree permissions',
  optionScopeNode: 'Node permissions',

  btnEditMenuElementOpen: 'Go to page',
  btnEditMenuElementRename: 'Rename',
  btnEditMenuElementAddPage: 'Add Page',
  btnEditMenuElementAddGroup: 'Add Pagegroup',
  btnEditMenuElementManagePermissions: 'Manage Permissions',
  btnEditMenuElementDelete: 'Delete',

  // Media library Asset Permissions
  btnAssetPermissions: 'Permissions',
  btnAssetPermissionsCancel: 'Cancel',
  btnAssetPermissionsSave: 'Save',

  hlEditUsergroups: 'User- / Group-Management',
  labelAddUsergroup: 'Add Group',
  placeholderAddUsergroup: 'Create group by typing groupname ...',
  placeholderAddUser: 'Add user by searching for username / e-mail ...',
  btnEditUsergroupAddUsers: 'Add Users (advanced)',
  btnEditUsergroupRename: 'Rename',
  btnEditUsergroupDelete: 'Delete',
  msgNoUsergroups: 'No groups available.',
  msgNoUsersInUsergroup: 'No users in this group.',
  labelUsergroupName: 'Groupname',

  btnSelectPlaceholder: 'Select...',

  hlAddMultipleUsers: 'Add Users',
  placeholderAddMultipleUsers: 'Search for users ...',
  btnSaveAddUsers: 'Add',
  msgAddMultipleUsersSaveSuccessful: 'Users have been added',
  msgAddMultipleUsersSaveFailed: 'Error adding users',
  msgAddMultipleUsersNothingFound: 'No matching users found.',
  tableHeaderUserSelected: ' ',
  tableHeaderUserUid: 'ID',
  tableHeaderUserUsername: 'Username',
  tableHeaderUserFullname: 'Name',
  tableHeaderUserMail: 'E-mail',
  tableHeaderUserCompany: 'Company',
  tableHeaderUserDepartment: 'Department',

  labelModuleTitleSketchLibrary: 'Sketch Library',
  labelModuleAttributeSketchLibraryName: 'Library Name',
  placeholderModuleAttributeSketchLibraryName: 'My Sketch Library',
  labelModuleAttributeSketchLibraryVersion: 'Version',
  labelModuleAttributeSketchLibraryDescription: 'Description',
  placeholderModuleAttributeSketchLibraryDescription:
    'Here you describe things like the intended usage of this library...',

  btnModuleSketchLibraryInstall: 'Install library',
  btnModuleSketchLibraryInstallDescription: 'Click here to download and install this library into Sketch',
  msgModuleSketchLibraryImageProcessing:
    'The preview image is still being processed and will not be displayed until the next reload of this page.',

  tabFavourites: 'Favourites',
  tabNotifications: 'Notifications',

  btnSiteAccessSwitchSelectBrand: 'Select Brand',

  labelModuleAttributeIframeSrc: 'IFrame URL',
  placeholderModuleAttributeIframeSrc: 'https://www.example-domain...',
  labelModuleIframeAutomaticResizing: 'Automatic IFrame resizing',
  statusModuleIframeAutomaticResizingDetecting: 'detecting...',
  statusModuleIframeAutomaticResizingGood: 'enabled',
  statusModuleIframeAutomaticResizingBad: 'not enabled',
  msgModuleIframeAutomaticResizingDescription:
    'In order to enable automatic IFrame resizing the iframe-resizer script needs to be included in the HTML of {src}.',

  labelModuleAttributeIconLibraryDefaultSvgBackgroundColor: 'Default SVG Background Color',
  placeholderModuleAttributeIconLibraryDefaultSvgBackgroundColor: '#ffff00',
  msgModuleAttributeIconLibraryDefaultSvgBackgroundColorHelp:
    'Valid CSS color (has to be one of SVG Background Colors).',
  labelModuleAttributeIconLibraryDefaultSvgFillColor: 'Default SVG Fill Color',
  placeholderModuleAttributeIconLibraryDefaultSvgFillColor: '#ffff00',
  msgModuleAttributeIconLibraryDefaultSvgFillColorHelp: 'Valid CSS color (has to be one of SVG Fill Colors).',
  labelModuleAttributeIconLibraryListItemStyles: 'List item CSS Styles',
  placeholderModuleAttributeIconLibraryListItemStyles: ' ',
  msgModuleAttributeIconLibraryListItemStylesHelp:
    'Custom CSS styles code. E.g. for colorizing SVGs (use CSS filter see https://codepen.io/sosuke/pen/Pjoqqp).',
  labelModuleAttributeIconLibraryPngOutputSizes: 'PNG Output Sizes',
  placeholderModuleAttributeIconLibraryPngOutputSizes: '16,32,64',
  msgModuleAttributeIconLibraryPngOutputSizesHelp: 'Comma-separated list of available PNG download sizes.',
  labelModuleAttributeIconLibraryRealm: 'Realm',
  placeholderModuleAttributeIconLibraryRealm: 'icon_library',
  msgModuleAttributeIconLibraryRealmHelp: 'Default `icon_library`. Do not change unless you know what to do.',
  labelModuleAttributeIconLibrarySearchCategories: 'Predefined Search Categories',
  placeholderModuleAttributeIconLibrarySearchCategories: 'Test,Marketing,Design',
  msgModuleAttributeIconLibrarySearchCategoriesHelp: 'Comma separated list of categories.',
  labelModuleAttributeIconLibrarySvgBackgroundColors: 'SVG Background Colors',
  placeholderModuleAttributeIconLibrarySvgBackgroundColors: 'red|#ffff00|rgb(200,30,167)',
  msgModuleAttributeIconLibrarySvgBackgroundColorsHelp: 'Valid CSS colors separated by "|".',
  labelModuleAttributeIconLibrarySvgFillColors: 'SVG Fill Colors',
  placeholderModuleAttributeIconLibrarySvgFillColors: 'red|#ffff00|rgb(200,30,167)',
  msgModuleAttributeIconLibrarySvgFillColorsHelp: 'Valid CSS colors separated by "|".',

  msgAnchorModuleHeadlineHidden: 'This headline is only visible while editing the page.',

  msgDeleteChecklistItemHint: 'Delete: Remove item',
  msgMoveUpChecklistItemHint: 'Up: Move item one position up/left',
  msgMoveDownChecklistItemHint: 'Down: Move item one position down/right',
  labelModuleChecklistNewItem: 'New item',

  hlModalConfirm: 'Please confirm',
  labelModalConfirmYes: 'Yes',
  labelModalConfirmNo: 'No',
  msgConfirmRemoveFavourite: 'Do you really want to remove this asset from your favorites list?',
  btnModalRemoveFavouriteYes: 'Remove',
  btnModalRemoveFavouriteNo: 'Cancel',
  btnFavouriteSetHint: 'Add to favorites',
  btnFavouriteUnsetHint: 'Remove from favorites',

  msgModuleFileEmpty: 'No file selected.',

  hlMyFiles: 'MyFiles',
  hlMyFilesSub: 'Manage and Share Your Assets',
  msgMyFilesDescription:
    'The MyFiles function is like a collaborative workspace where you can collect files from the Media Library or your desktop. You can manage your favorite assets by grouping them into hierarchical folders. Similar to a dropbox, MyFiles then enables you to send a link for selected items or entire folders to colleagues or partners. Individual files may have a maximum size of 2 GB. The total storage volume for your MyFiles folder is limited to 5 GB.',

  hlChangelog: 'Newsfeed',
  btnChangelogItemLink: 'Go to page',
  btnChangelogItemEdit: 'Edit comment',
  btnChangelogItemDelete: 'Delete item',
  btnChangelogItemSave: 'Save comment',
  btnChangelogItemCancel: 'Cancel',
  msgChangelogEmpty: 'No items found.',
  msgChangelogLoading: 'Items loading...',
  msgChangelogItemAlreadySent: '(already sent)',
  msgChangelogItemDelete: 'Do you really want to delete this item?',
  btnChangelogItemDeleteConfirm: 'Yes, delete',
  btnChangelogItemDeleteCancel: 'Cancel',
  btnChangelogLoadMore: 'Load more',

  btnDelete: 'Delete item',
  btnDeleteConfirm: 'Yes, delete',
  btnDeleteCancel: 'Cancel',
  msgItemDelete: 'Do you really want to delete this item?',

  hlNewsletterDialog: 'Newsletter dispatch',
  msgNewsletterDialog: 'Please formulate the intro text for your newsletter in the text field below.',
  btnNewsletterDialogSend: 'Submit now',
  btnNewsletterDialogCancel: 'Cancel',
  btnNewsletterNew: 'Create newsletter',
  msgNewsletterChooseItems: 'Select the entries to be listed in the newsletter.',
  btnNewsletterChooseItemsCancel: 'Cancel',
  btnNewsletterChooseItemsConfirm: 'Create newsletter',
  hlImageCropDialog: 'Crop Image',
  btnImageCropDialogConfirm: 'Save selection',
  btnImageCropDialogCancel: 'Cancel',
  btnImageCropDialogReset: 'Reset settings',
  labelImageCropDialogOriginalSize: 'Original Size',
  labelImageCropDialogSelectPreset: 'Select Format',
  labelImageCropDialogCustomFormat: 'Adjust Format',
  labelAspectRatioFree: 'Free',
  labelAspectRatio1to1: '1:1 aspect ratio',
  labelAspectRatio2to1: '2:1 aspect ratio',
  labelAspectRatio4to3: '4:3 aspect ratio',
  labelAspectRatio16to9: '16:9 aspect ratio',

  btnMetaInformation: 'Meta-Information',
  msgCancelMetaHint: 'Discard changes to meta information',
  msgSaveMetaHint: 'Update meta information',
  labelMetaTitle: 'Titel',
  labelMetaDescription: 'Description',
  placeholderMetaTitle: 'Add an appropriate title',
  placeholderMetaDescription: 'Complement the meta information with a brief description of the content',
  hlMetaPanel: 'Meta-Information',
  msgMetaPanel:
    'Prepare this page for teasers. Add a preview image for small and big teasers, add a title and give a brief description.',
  labelMetaImageAspectRatioSquare: '1:1',
  labelMetaImageAspectRatioRect: '2:1',
  msgMetaTopics: 'Select the topics that match best with the page`s content: One main topic and one optional subtopic.',
  hlMainTopic: 'Main topic',
  hlSubTopic: 'Subtopic',

  btnNewsfeedCustomize: 'Personalize',
  hlNewsfeedCustomizeDialog: 'Personalize your newsfeed',
  msgNewsfeedCustomizeDialog: 'Choose at least 3 topics that interest you.',
  msgNewsfeedCustomizeRefine: 'Refine your selection for {topicName}',
  btnNewsfeedCustomizeDialogCancel: 'Later',
  btnNewsfeedCustomizeDialogReset: 'Reset',
  btnNewsfeedCustomizeDialogConfirm: 'Confirm personalization',
  btnNewsfeedCustomizeDialogClose: 'Close dialog',
  hlNewsfeedCustomizeConfirm: 'Personalization done.',
  msgNewsfeedCustomizeConfirm: 'Enjoy!',
  msgNewsfeedCustomizeConsent:
    'By providing your interests we can filter your newsfeed. You can change or delete these settings anytime.',
  hlNewsfeedFeed: 'News based on your preferences',
  labelModuleNewsfeedTitle: 'Newsfeed title',
  placeholderModuleNewsfeedTitle: 'z.B. News for your topics',
  btnNewsfeedLink: 'Go to site',

  hlFrontpageHeroSalutation: 'Hallo {name}',
  msgFrontpageHeroGreeting: 'Nice to see you.',

  msgFilterSelectCount: '{count} of {max} filters selected',
  btnMediaLibraryActionDetails: 'Details',
  btnMediaLibraryActionDownload: 'Download',
  btnMediaLibraryActionAddToMyfiles: 'Add to Myfiles',
  btnMediaLibraryActionUseWeb2Print: 'Use Web2Print',
  btnMediaLibraryActionCopyId: 'Copy ID',
  btnMediaLibraryActionCopyLink: 'Copy asset link',
  btnMediaLibraryActionCropImage: 'Crop Image',
  btnMediaLibraryActionZoom: 'Zoom',
  btnMediaLibraryActionOpenCollection: 'Open Collection',
  btnMediaLibraryActionPlay: 'Play video',
  btnMediaLibraryActionMore: 'more actions ...',
  msgMediaLibraryResultCount: '{count} Assets',
  btnMediaLibraryFavouritesOnly: 'My Favorites',
  labelMediaLibrarySortBy: 'Sort by',
  labelMediaLibrarySortByDefault: 'Relevance',
  labelMediaLibrarySortOrder: 'Order',
  labelMediaLibraryViewSlider: 'View',
  hlMediaLibraryDownloadModal: 'Download',
  hlMediaLibraryAddToMyFilesModal: 'Add to Myfiles',
  btnMediaLibraryResetFilter: 'Reset',
  btnMediaLibraryFilterSelectOpen: 'Add Filters',
  btnMediaLibraryFilterSelectClose: 'Save',
  btnMediaLibraryResetTags: 'Clear all',
  labelMediaLibraryTags: 'Filtered by:',
  labelMediaLibraryLoadingError: 'There was a problem loading the Media-Library.',
  msgMediaLibraryCopyId: 'ID copied to clipboard.',
  msgMediaLibraryCopyLink: 'Link to asset copied to clipboard.',
  btnMediaLibraryCloseCollection: 'Back to overview',
  labelMediaLibraryCollectionDescription: 'Description',
  labelMediaLibraryCollectionCreator: 'Creator',
  labelMediaLibraryCollectionId: 'ID',
  labelMediaLibraryCollectionLink: 'Link',
  msgMediaLibraryAssetRestricted:
    'This asset is subject to limited rights of use. I have read and accept these restrictions.',
  labelMediaLibraryMasterBubble: 'Global Master',
  labelMediaLibraryVersionBubble: 'Local Version',

  btnMediaLibraryActionUseRaiseRequestAssets: 'Raise Request',
  mediaLibraryRaiseRequestDescription:
    ' Your raise request has been submitted successfully. We will review it and get back to you shortly.',
  mediaLibraryRaiseRequestModalHeadline: 'Request Submitted Successfully',
  btnMediaLibraryRaiseRequestClose: 'Close',
  btnMediaLibraryRaiseRequestOK: 'OK',
  btnMediaLibraryRaiseRequestCancel: 'Cancel',

  hlMediaLibrary: 'Media Library',
  hlMediaGallery: 'Media Gallery',
  labelModuleAttributeMediaLibraryRealm: 'Realm',
  placeholderModuleAttributeMediaLibraryRealm: 'e.g. "asset_library"',
  msgModuleAttributeMediaLibraryRealmHelp: 'Standard `asset_library`. Do not change unless you know what to do.',
  labelModuleAttributeMediaLibraryQuery: 'Query',
  placeholderModuleAttributeMediaLibraryQuery: 'e.g. "sort=title_en&order=asc"',
  msgModuleAttributeMediaLibraryQueryHelp: 'Predefined Search-Query.',
  msgMediaLibraryMaintenance:
    'The Brand Portal is under maintenance today.<br>During this time, the Media Library is not accessible.<br><br>Please apologize any inconvenience you may have. For any questions or urgent matters, please contact us via branding@linde.com<br><br>Best regards<br>The Linde Brand Portal team',

  labelContentSearchEmpty: 'No results here, but check out the Media Library results.',
  labelMediaLibrarySearchEmpty: 'No results here, but check out the Brand Guide results.',

  msgWeb2PrintInactive:
    'This document is not compliant with the current corporate design and cannot be started any longer. Please reach out to the responsible person of the Web2Print master template stated in the asset details to find out about the plans for rebranding.',
  msgWeb2PrintMaintenance:
    'The Brand Portal is under maintenance today.<br>During this time, the Web2Print tool is not accessible.<br><br>Please apologize any inconvenience you may have. For any questions or urgent matters, please contact us via branding@linde.com<br><br>Best regards<br>The Linde Brand Portal team',

  hlDownloadRequest: 'Download Request',

  hlJsmContact: 'JSM Contact',
  labelModuleAttributeJsmContactDataKey: 'JSM Data Key',
  placeholderModuleAttributeJsmContactDataKey: ' ',
  msgModuleAttributeJsmContactDataKeyHelp: ' ',
  msgModuleAttributeJsmContactDataKeyError: 'Please use only a,b,c,d,e,f letters, digits and dash chars',
  labelModuleAttributeJsmContactPortalBaseUrl: 'Base url of the customer service portal',
  placeholderModuleAttributeJsmContactPortalBaseUrl: ' ',
  msgModuleAttributeJsmContactPortalBaseUrlHelp: 'Required for uploads greater than 5 MB',
  msgModuleAttributeJsmContactPortalBaseUrlError:
    'Please enter url in form: https://customer-name.domain/servicedesk/customer/portal/1',
  labelModuleAttributeJsmContactRequestTypeId: 'Id of JSM request type to load',
  placeholderModuleAttributeJsmContactRequestTypeId: ' ',
  msgModuleAttributeJsmContactRequestTypeIdHelp:
    'Per default the request type assigned to the JSM widget will be loaded',
  msgModuleAttributeJsmContactRequestTypeIdError: 'Please enter a positive numerical value',
};
