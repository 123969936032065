import clsx from 'clsx';
import { ChangeEvent, PureComponent } from 'react';

export type IInputBaseProps = {
  label?: string;
  name?: string;
  placeholder?: string;
  labelAfter?: boolean;
  className?: string;
  autoComplete?: string;
  disabled?: boolean | undefined;
  required?: boolean | undefined;
};

export type IInputNumberProps = IInputBaseProps & {
  type: 'number';
  onChange: (value: number) => void;
  value: number;
};

export type IInputPasswordProps = IInputBaseProps & {
  type: 'password';
  onChange: (value: string) => void;
  value: string;
};

export type IInputEmailProps = IInputBaseProps & {
  type: 'email';
  onChange: (value: string) => void;
  value: string;
};

export type IInputTextProps = IInputBaseProps & {
  type: 'text';
  onChange: (value: string) => void;
  value: string;
};

export type IInputCheckboxProps = IInputBaseProps & {
  type: 'checkbox';
  onChange: (value: boolean) => void;
  value: boolean;
};

export type IInputProps =
  | IInputNumberProps
  | IInputPasswordProps
  | IInputEmailProps
  | IInputTextProps
  | IInputCheckboxProps;

export class Input extends PureComponent<IInputProps> {
  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange, value: oldValue } = this.props;
    const target = event.target;
    const newValue = target.type === 'checkbox' ? target.checked : target.value;
    (onChange as (value: typeof oldValue) => void).call(this, newValue);
    // onChange(value);
  };

  render() {
    const { name, label, labelAfter, type, value, placeholder, className, autoComplete, disabled, required } =
      this.props;
    const classes = clsx(
      {
        'c-input': true,
      },
      className,
    );
    // make sure to render an empty string if there is no value so we dont
    // switch from controlled to uncontrolled input
    const sanitizedValue = value === null || value === undefined ? '' : value.toString();
    const labelEl = label && (
      <label className="c-label" htmlFor={name}>
        {label}
      </label>
    );

    return (
      <div className={`c-form-element c-form-element--type-textfield c-form-element--${name}`}>
        {label && !labelAfter && labelEl}
        <input
          id={name}
          name={name}
          className={classes}
          type={type}
          placeholder={placeholder}
          value={sanitizedValue}
          onChange={this.handleChange}
          autoComplete={autoComplete}
          disabled={disabled}
          required={required}
        />
        {label && labelAfter && labelEl}
      </div>
    );
  }
}

export default Input;
