import clsx from 'clsx';
import { ChangeEvent, MouseEvent } from 'react';

export type CheckboxProps = {
  label?: string;
  name?: string;
  value: boolean;
  onChange: (value: boolean) => void;
  className?: string;
  disabled?: boolean | undefined;
  required?: boolean | undefined;
};

export const Checkbox = ({ name, label, value, className, disabled, required, onChange }: CheckboxProps) => {
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : Boolean(target.value);
    onChange?.(value);
  }

  function interceptOnClickEvent(event: MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
  }

  return (
    <div className={clsx('c-form-element c-form-element--type-checkbox', className)} onClick={interceptOnClickEvent}>
      <label className={clsx('o-input', value === true && 'checked')}>
        <span className="c-checkmark" />
        <div className="c-label">{label}</div>
        <input
          name={name}
          className="c-checkbox"
          type="checkbox"
          checked={value === true}
          onChange={handleChange}
          disabled={disabled}
          required={required}
        />
      </label>
    </div>
  );
};

export default Checkbox;
