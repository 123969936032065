import clsx from 'clsx';
import { PureComponent } from 'react';

interface IRadioProps<T> {
  name?: string;
  value: T;
  values: {
    value: T;
    label: string;
  }[];
  className?: string;
  onChange: (value: T) => void;
  disabled?: boolean | undefined;
  required?: boolean | undefined;
}

export class Radio<T = string> extends PureComponent<IRadioProps<T>> {
  render() {
    const { values, value, className, onChange, disabled, required } = this.props;
    const classes = clsx(
      {
        'c-form-element': true,
        'c-form-element--type-radio': true,
      },
      className,
    );

    return (
      <div className={classes}>
        {values.map((opt, i) => (
          <label key={i} className={`o-input ${value === opt.value ? 'checked' : ''}`}>
            <span className="c-radiobutton" />
            <div className="c-label">{opt.label}</div>
            <input
              className="c-radio"
              type="radio"
              checked={value === opt.value}
              onChange={() => onChange(opt.value)}
              disabled={disabled}
              required={required}
            />
          </label>
        ))}
      </div>
    );
  }
}

export default Radio;
