import clsx from 'clsx';
import { MouseEvent, PureComponent, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ControlActions } from '~store/actions';
import { getAbsoluteUrl } from '~store/getter';

interface IButtonProps {
  type?: 'button' | 'reset' | 'submit';
  name?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  label?: ReactNode;
  hideLabel?: boolean;
  className?: string;
  iconName?: string;
  noPadding?: boolean;
  iconClassName?: string;
  iconCentered?: boolean;
  iconPosition?: string;
  iconSize?: string;
  visible?: boolean;
  href?: string;
  target?: string;
  rel?: string;
  download?: boolean;
  title?: string;
  onDownloadFile?: (url: string) => void;
  children?: ReactNode;
}

export class Button extends PureComponent<IButtonProps> {
  static defaultProps: IButtonProps = {
    disabled: false,
    type: 'button',
    className: 'c-button',
    label: null,
    noPadding: false,
    visible: true,
    iconCentered: true,
    title: '',
    target: '_self',
  };

  render() {
    const {
      name,
      onClick,
      type,
      disabled,
      label,
      hideLabel,
      className,
      iconName,
      iconClassName,
      children,
      noPadding,
      visible,
      iconCentered,
      href,
      target,
      rel,
      iconSize,
      download,
      iconPosition,
      title,
      onDownloadFile,
    } = this.props;

    if (!visible) {
      return null;
    }

    const _icon = iconName && (
      <i
        className={clsx(
          {
            'c-icon-custom': true,
            'u-vertical-middle': true,
            ['c-icon-custom--' + iconName]: true,
            'c-icon-custom--vertical-centered': iconCentered,
            ['u-size-' + iconSize]: Boolean(iconSize),
          },
          iconClassName,
        )}
      />
    );

    const _label = label && (
      <span
        className={clsx({
          'u-sr-only': hideLabel,
          'u-margin-left-md': !hideLabel && iconName && (!iconPosition || iconPosition === 'left'),
          'u-margin-right-md': !hideLabel && iconName && iconPosition === 'right',
          'u-vertical-middle': true,
        })}
      >
        {label}
      </span>
    );

    return href ? (
      <a
        href={href}
        target={target}
        rel={rel}
        title={title}
        className={clsx(
          {
            'u-padding-vertical-none': noPadding,
            'u-padding-horizontal-none': noPadding,
          },
          className,
        )}
        {...(name ? { name } : {})}
        {...(download ? { download: true } : {})}
        onClick={() => download && onDownloadFile(getAbsoluteUrl(href))}
      >
        {iconPosition !== 'right' && _icon}
        {_label}
        {iconPosition === 'right' && _icon}
        {children}
      </a>
    ) : (
      <button
        type={type}
        title={title}
        className={clsx(
          {
            'u-padding-vertical-none': noPadding,
            'u-padding-horizontal-none': noPadding,
          },
          className,
        )}
        {...(name ? { name } : {})}
        onClick={onClick}
        disabled={disabled}
      >
        {iconPosition !== 'right' && _icon}
        {_label}
        {iconPosition === 'right' && _icon}
        {children}
      </button>
    );
  }
}

type StateProps = Omit<IButtonProps, keyof IButtonProps>;
const mapStateToProps = (): StateProps => ({});

type DispatchProps = Pick<IButtonProps, 'onDownloadFile'>;
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    onDownloadFile: (filename: string) => {
      dispatch(ControlActions.downloadFile({ filename }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Button);
